import styled from "styled-components";
import { Rem } from "../../commons/Theme";
import { StyledMap } from "../OpenLayers/style";

export const StyledMapBoxRetailers  = styled.div`
  position: relative;

  ${StyledMap} {
    max-height: 68vh;
    font-weight: normal;
  }
`;

export const StyledMapBoxRetailersOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99;
  backdrop-filter: blur(3px);
`;

export const StyledMapBoxRetailersFormWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
`;

export const StyledMapBoxRetailersForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${Rem(16)};
  width: fit-content;
  height: 100%;
  margin: 0 auto;
`;
